import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import {graphql} from "gatsby"
import Img from 'gatsby-image'
import AnimateComponent from '../components/animate-component'
import SpecializationCarousel from '../components/specialization-carousel'

class SpecializationTemplate extends React.Component {

    componentDidMount() {
        this.initCarousels();
    }

    componentWillUnmount() {
        this.unmountCarousels();
    }

    initCarousels = () => {
        if (this.props.location.pathname.includes('/specjalizacje/')) {
            const carousel = require('../js/carousel');
            this.specializationCarousel = new carousel('specialization-details');
            this.specializationCarousel.run();
        }      
    }

    unmountCarousels = () => {
        this.specializationCarousel.stop();
        this.specializationCarousel  = null;     
    }

    render () {

    const post = this.props.data.wordpressWpSpecjalizacje
        return (
            <Layout>
                <SEO title={`${post.title} | Świecki Kancelaria`} />
                <AnimateComponent
                    styleName="specialization-details specialization-details--building"
                    id="specialization-details">
                    <div className="specialization-details__container">
                            <p className="specialization-details__back-arrow" onClick={()=> window.history.back()}>
                                Powrót
                            </p>
                        <header>
                            <div aos='fade-up' className='specialization-details__header-box wow fadeInUp' delay='0'>
                                {post.featured_media.localFile.publicURL &&
                                <img className="specialization-details__icon" src={post.featured_media.localFile.publicURL} alt="" />
                                }
                                <h1>
                                    {post.title}
                                </h1>
                            </div>                 
                            <div className="specialization-details__textContainer">                      
                                {post.acf.slogan !== '' &&
                                <h2 tag='h2' aos='fade-up' delay='300' className="wow fadeInUp">
                                    {post.acf.slogan}
                                </h2>
                                }
                                {post.acf.zajawka !== '' && 
                                <p tag='p' aos='fade-up' delay='300' className="wow fadeInUp">
                                    {post.acf.zajawka}
                                    </p>                     
                                }
                                <div aos='fade-up' delay='300' className="wow fadeInUp">
                                {post.content !== '' && 
                                    <div className="p_normal" dangerouslySetInnerHTML={{ __html: `${post.content}` }} />
                                }
                                </div>
                            </div>
                            <div className="specialization-details__imageContainer">
                            {post.acf.zdjecie &&
                            <Img
                                fluid={post.acf.zdjecie.localFile.childImageSharp.fluid}
                                className="specialization-details__photo"
                            />
                            }
                            </div>
                        </header>                  
                        <div className="specialization-details__content">
                            {post.acf.tytul_zalet !== '' &&
                            <h3 tag='h3' aos='fade-up' delay='100' className="wow fadeInUp">
                                {post.acf.tytul_zalet}              
                            </h3>
                            }
                            {post.acf.opis_zalet !== '' && 
                            <p tag='p' aos='fade-up' delay='100' className="wow fadeInUp">
                                {post.acf.opis_zalet}                     
                            </p>
                            }
                            <ul>
                            {post.acf.zalety !== null && post.acf.zalety.map(( zaleta,i ) => (     
                                <li aos='fade-up' tag='li' key={i} className="wow fadeInUp">
                                {zaleta.tresc}
                                </li>
                            ))}
                            </ul>
                        </div>               
                    </div>
                    <SpecializationCarousel title='Zobacz nasze pozostałe specjalizacje'/>
                </AnimateComponent>
                
            </Layout>
        )
    }
}

export default SpecializationTemplate
export const pageQuery = graphql `
query($id: String!) {
    wordpressWpSpecjalizacje( id: { eq: $id } ){
        title
        content
        slug
        featured_media{
            localFile {
                publicURL
            }
        }
        acf{
            slogan
            zajawka
            zalety {
                tresc
            }
            tytul_zalet
            opis_zalet
            zdjecie {
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 594, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
}
`